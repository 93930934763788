/**
 * @file App.tsx
 * @description Point d'entrée principal de l'application avec gestion de l'authentification, des routes, et des notifications Toast.
 */

import { BrowserRouter } from "react-router-dom";
import "./App.css";
import RouteConfig from "./routes/RouteConfig";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileDropdown from "./components/Profile/ProfileDropdown";
import { AuthProvider } from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { AppProvider } from "./context/AppContext";

/**
 * @component App
 * @description Composant principal de l'application qui englobe le routeur, les configurations d'authentification, et l'affichage des notifications.
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
    },
  },
});

const storagePersistor = createSyncStoragePersister({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persister: storagePersistor,
});
function App() {
  return (
    <>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          {/* Le routeur permet la gestion de navigation dans l'application */}
          <BrowserRouter>
            {/* AuthProvider gère l'état global de l'authentification pour l'application */}
            <AuthProvider>
              <AppProvider>
                <div className="screen">
                  {/* L'en-tête de l'application avec le menu utilisateur */}
                  <div className="screen-header">
                    <ProfileDropdown />
                  </div>
                  {/* Section principale où les routes configurées sont rendues */}
                  <div className="middle">
                    <RouteConfig />
                  </div>
                  <footer></footer>
                </div>
                {/* Conteneur pour afficher les notifications globales */}
                <ToastContainer />
              </AppProvider>
            </AuthProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </div>
    </>
  );
}

export default App;
