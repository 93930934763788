/**
 * @file ProfileDropdown.tsx
 * @description Composant React représentant un menu déroulant de profil utilisateur avec un avatar, des options de menu et une fonctionnalité de déconnexion.
 */

import React, { useEffect, useState } from "react";
import { Avatar, Dropdown, Menu, Button, Layout } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom"; // Importation de Link pour la navigation
import { useAuth } from "../../context/AuthContext"; // Importation du contexte Auth pour la gestion de l'authentification
import "./ProfileDropdown.css";

const { Header } = Layout;

/**
 * @component ProfileDropdown
 * @description Affiche un menu déroulant contenant les options de profil pour l'utilisateur connecté, comme la déconnexion.
 * @returns {JSX.Element} Le menu déroulant de profil avec un avatar et des options de menu.
 */
const ProfileDropdown: React.FC = () => {
  // Récupération de l'utilisateur et de la fonction logout depuis le contexte d'authentification
  const { user, logout } = useAuth();

  // État pour stocker les initiales de l'utilisateur
  const [initials, setInitials] = useState<string>("");

  // Initialisation des initiales de l'utilisateur en fonction de son e-mail
  useEffect(() => {
    if (user?.email) {
      const nameParts = user.email.split(".");
      const initials = nameParts
        .slice(0, -1) // Ignorer la partie domaine de l'email
        .map((part) => part[0]) // Récupérer la première lettre de chaque partie
        .join("");
      setInitials(initials.toUpperCase()); // Mettre en majuscules les initiales
    }
  }, [user?.email]);
  /**
   * @method logout
   * @description Fonction déclenchée lorsque l'utilisateur clique sur "Se déconnecter".
   */

  // Menu du dropdown, contient les placeholders et l'option de déconnexion
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button type="text">Placeholder 1</Button>
      </Menu.Item>
      <Menu.Item key="2">
        <Button type="text">Placeholder 2</Button>
      </Menu.Item>
      <Menu.Item key="3" onClick={logout} data-testid="logout">
        <LogoutOutlined />
        Se déconnecter
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="app-header">
      {/* Nom de l'application cliquable si l'utilisateur est connecté */}
      {user ? (
        <Link to="/cabinet" className="app-name-link">
          <div className="app-name">COMPTANOVA</div>
        </Link>
      ) : (
        <div className="app-name">COMPTANOVA</div>
      )}

      {/* Menu déroulant affichant l'avatar de l'utilisateur avec ses initiales */}
      {user ? (
        <div className="user-info">
          <Dropdown overlay={menu} placement="bottomRight" data-testid="dropDownMenu">
            <Avatar
              className="profile-avatar"
              style={{ backgroundColor: "#007bff" }}
              data-testid="avatar"
            >
              {initials || <UserOutlined />}{" "}
              {/* Affichage des initiales ou d'une icône si non disponible */}
            </Avatar>
          </Dropdown>
        </div>
      ) : (
        <div className="empty-header" />
      )}
    </Header>
  );
};

export default ProfileDropdown;
