/**
 * @file index.tsx
 * @description Point d'entrée principal de l'application React.
 * Configure et rend le composant App dans le DOM,
 * initialisant également l'intercepteur Axios.
 */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axiosInstance from "./utils/axiosInterceptor";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
console.log("Axios interceptor initialized:", axiosInstance);
