/**
 * @file AppContext.tsx
 * @description Contexte général de l'application
 */
import { createContext, ReactNode, useContext } from "react";
import { FC } from "react";
import { useAuth } from "./AuthContext";
import { HttpStatusCode } from "axios";
import axiosInstance from "../utils/axiosInterceptor";
import { CompteCategory } from "./DownloadQuestionnaireContext";
import { MatchingPlanComptableUnifieDto } from "./CompanyContext";
import { useQuery } from "@tanstack/react-query";
import { JournalCodeNatureSuggestion } from "./UploadFecContext";

/**
 * @interface LegalCategory
 * @description Représente la catégorie légale d'une entreprise.
 */
export interface LegalCategory {
  id: string;
  categoryCode: string;
  categoryLib: string;
}

/**
 * @interface CodeNaf
 * @description Représente les informations liées au code NAF d'une entreprise.
 */
export interface CodeNaf {
  id: string;
  division: string;
  naf: string;
  activity: string;
}
/**
 * @interface   AppContextType
 * @description Interface pour le contexte général.
 */
export interface AppContextType {
  codeNafs: CodeNaf[] | undefined;
  legalCategories: LegalCategory[] | undefined;
  compteCategoryDtos: CompteCategory[] | undefined;
  matchingPlanComptableUnifieDtos: MatchingPlanComptableUnifieDto[] | undefined;
  natureSuggestionList: JournalCodeNatureSuggestion[]; // Liste des suggestions pour les codes journaux.
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const queryResult = useQuery({
    queryKey: ["generalInfo"],
    queryFn: () => {
      return fetchGeneralInfo();
    },
    enabled: true,
    refetchOnMount: "always",
    refetchOnWindowFocus: true,
  });
  const { userCabinet } = useAuth();

  const fetchGeneralInfo = async (): Promise<any> => {
    console.log("Fetching general info");
    try {
      const response = await axiosInstance.get(
        `/api/cabinets/${userCabinet?.id}/informations`
      );
      if (response.status === HttpStatusCode.Ok) {
        return response.data;
      } else {
        console.error("Failed to fetch general info");
        throw new Error("Échec du téléchargement des infos généraux");
      }
    } catch (error) {
      throw new Error("Échec du téléchargement des infos généraux");
    }
  };

  return (
    <AppContext.Provider
      value={{
        codeNafs: queryResult.data != null ? queryResult.data.codeNafDtos : [],
        legalCategories:
          queryResult.data != null ? queryResult.data.legalCategoryDtos : [],
        compteCategoryDtos:
          queryResult.data != null ? queryResult.data.compteCategoryDtos : [],
        matchingPlanComptableUnifieDtos:
          queryResult.data != null
            ? queryResult.data.matchingPlanComptableUnifieDtos
            : [],
        natureSuggestionList:
          queryResult.data != null ? queryResult.data.natureSuggestionList : [],
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

/**
 * @function useApp
 * @description Hook personnalisé pour accéder au contexte d'application.
 * @returns {AuthContextType} - Retourne le contexte d'application.
 * @throws {Error} - Lance une erreur si utilisé hors du `AppProvider`.
 */
export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp doit etre utilisé sous AppProvider");
  }
  return context;
};
